import React from 'react';
import aboutUs from '../data/about-us';
import '../styles/AboutUs.css';
import FlipPage from 'react-flip-page';

export default function FlipBook () {
  return (
    <FlipPage showSwipeHint="true" className="cursor-grab overflow-hidden ml-[32px] mr-[21px]" style={{marginTop:"-50px"}} orientation="horizontal" height="594" width="985"  >
      {aboutUs.map((aboutUs, index) => (
        <div key={index} className="relative w-[100%]">
          <img src={aboutUs.pages} alt="" className="w-[100%]" />
        </div>
      ))}
    </FlipPage>
  );
}