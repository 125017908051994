import React from 'react';
import { MenuItems } from './Menus';
import { DesktopNavRoutes } from '../routes/DesktopNavRoutes';

export default function Navbar() {
  return (
    <nav className="bg-[#cff0ff] h-[50px] w-[100%] flex max-[992px]:hidden justify-center items-center relative">
      <ul className="bg-transparent list-none flex gap-[80px]">
        {DesktopNavRoutes.map((menu) => {
          return <MenuItems 
            key={menu.id} 
            id={menu.id} 
            items={menu} 
          />;
        })}
      </ul>
    </nav>
  );
}