import { useState, useEffect, useRef } from 'react';
import MenuDropdowns from './MenuDropdowns';

const MenuItems = ({ items }) => {
  const [dropdown, setDropdown] = useState(false);
  const handleClicked = () => setDropdown(!dropdown);

  const [windowDimension, detectWidth] = useState({
    winWidth: window.innerWidth
  });

  const detectSize = () => {
    detectWidth({
      winWidth: window.innerWidth
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension.winWidth]);

  if (windowDimension.winWidth < 992 && dropdown) {
    setDropdown(false);
  }

  let ref = useRef();
  
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
     // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);
  
  return (
    <>
      {items.submenu ? (
        <>
          <li className="inline-block max-[992px]:hidden list-none align-middle m-0 text-center" ref={ref}>
            <button className={`bg-transparent border-0 text-[1.25rem] font-normal cursor-pointer tracking-[0.9px] leading-[105%] flex ${dropdown ? "text-[#630bad]" : "text-[#170556]"}`} type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"}
            onClick={handleClicked}>
              {items.label}&nbsp;
              <span className={`block w-[15px] h-[15px] text-[12px] ${dropdown ? "open-dropdown-arrow-icon" : "close-dropdown-arrow-icon"}`}></span>
            </button>
            <MenuDropdowns 
              submenus={items.submenu} 
              dropdowns={dropdown} 
            />
          </li>
        </>
      ) : (
        <li className="inline-block max-[992px]:hidden list-none align-middle m-0 text-center">
          <a 
            className="no-underline flex"
            href={items.path}
            target={items.target}
            rel={items.rel}
            >
            <span className="bg-transparent border-0 text-[1.25rem] font-normal cursor-pointer tracking-[0.9px] leading-[105%] text-[#170556] active:text-[#630bad]">
              {items.label}
            </span>
          </a>
        </li>
      )}
    </>
  );
};

export default MenuItems;