import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ContextProvider } from '../context/Context';
import { ScrollToTop } from '../components';

const Home = lazy(() => import('../pages/Home'));
const EndangeredAnimals = lazy(() => import('../pages/EndangeredAnimals'));
const SurvivalTips = lazy(() => import('../pages/SurvivalTips'));
const LittleHeroes = lazy(() => import('../pages/LittleHeroes'));
const ChildDirector = lazy(() => import('../pages/ChildDirector'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const ContactForm = lazy(() => import('../pages/ContactForm'));
const AboutUs = lazy(() => import('../pages/AboutUs'));
const AllAboutKids = lazy(() => import('../pages/AllAboutKids'));
const MediaCentre = lazy(() => import('../pages/MediaCentre'))
const ZiggyStory = lazy (() => import('../pages/ZiggyStory'))
const DisasterRelief = lazy(() => import('../pages/DisasterRelief'));
const CharitableOperations = lazy(() => import('../pages/CharitableOperations'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const EnvironmentalPolicy = lazy(() => import('../pages/EnvironmentalPolicy'));
const DonationsPolicy = lazy(() => import('../pages/DonationsPolicy'));
const FundraisingPolicy = lazy(() => import('../pages/FundraisingPolicy'));
const PageUnderDev = lazy(() => import('../pages/PageUnderDev'));
const AppUnderDev = lazy(() => import('../pages/AppUnderDev'));
const NotFound = lazy(() => import('../pages/NotFound'));

function ComponentRoutes() {
	return (
    <ScrollToTop>
      <Suspense fallback={<div><h1>Loading...</h1></div>}>
        <ContextProvider>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/endangered-animals" element={<EndangeredAnimals />} />
            <Route path="/survival-tips" element={<SurvivalTips />} />
            <Route path="/little-heroes-big-missions" element={<LittleHeroes />} />
            <Route path="/become-a-child-director" element={<ChildDirector />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/contact-form" element={<ContactForm />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/it's-all-about-kids" element={<AllAboutKids />} />
            <Route path="/media-centre" element={<MediaCentre />} />
            <Route path="/ziggy's-story" element={<ZiggyStory />} />
            <Route path="/disaster-relief" element={<DisasterRelief />} />
            <Route path="/charitable-operations" element={<CharitableOperations />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/environmental-policy" element={<EnvironmentalPolicy />} />
            <Route path="/donations-policy" element={<DonationsPolicy />} />
            <Route path="/fundraising-policy" element={<FundraisingPolicy />} />
            <Route path="/page-under-development" element={<PageUnderDev />} />
            <Route path="/app-under-development" element={<AppUnderDev />} />
            <Route status={404} element={<NotFound />} />
          </Routes>
        </ContextProvider>
      </Suspense> 
    </ScrollToTop>
  );
}

export default ComponentRoutes;