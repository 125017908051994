import { createContext, useState, useEffect } from 'react';

const Context = createContext();

export const ContextProvider = ({ children }) => {
  // Detect browser window size
  const [windowDimension, detectW] = useState({
    winWidth: window.innerWidth
  });

  const detectSize = () => {
    detectW({
      winWidth: window.innerWidth
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension.winWidth]);

  // Font scaling for accessibility
  
  // State to manage the font scale
  const [fontScale, setFontScale] = useState(1.0);

  // Load font scale from local storage on component mount
  useEffect(() => {
    const storedFontScale = localStorage.getItem('fontScale');

    if (storedFontScale) {
      setFontScale(parseFloat(storedFontScale));
    }
  }, []);

  // Update local storage when font scale changes
  useEffect(() => {
    localStorage.setItem('fontScale', fontScale);
  }, [fontScale]);

  // Minimum and maximum font scales
  const minFontScale = 1.0;
  const maxFontScale = 1.5;

  // Functions to decrease and increase font scale
  const decreaseFontScale = () => {
    setFontScale(prevScale => Math.max(prevScale - 0.1, minFontScale));
  };

  const increaseFontScale = () => {
    setFontScale(prevScale => Math.min(prevScale + 0.1, maxFontScale));
  };

  // Function to reset font scale
  const resetFontScale = () => {
    setFontScale(1.0);
  };

  return (
    <Context.Provider value={{ 
      windowDimension,
      fontScale,
      decreaseFontScale,
      increaseFontScale,
      resetFontScale
    }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context;