import './BtnComp.css';

export default function BtnComp({btnIcon, btnText, btnType, btnLength }) {
  return (   
    <div className={`btn-base-properties ${btnType} ${btnLength}`}>
      {btnIcon !== "" ? (
      <div className={btnLength === "mini-btn" ? "mini-btn-icon" : "btn-icon"}>
        <img className="w-[100%]" src={btnIcon} alt="" />
      </div>
      ) : ""}
      {btnText}
    </div>
  );
}