import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CgMenu, CgClose } from 'react-icons/cg';
import MobileNavRoutes from '../../routes/MobileNavRoutes';

function MobileNavbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div>
      <div onClick={handleClick}>
        {click ? (
          <CgClose className="cursor-pointer w-[45px] h-[45px] text-[#fff]" />
        ) : (
          <CgMenu className="cursor-pointer w-[45px] h-[45px] text-[#fff]" />
        )}
      </div>
      <ul className={click ? "flex flex-col w-[100%] h-auto absolute z-[5] m-0 py-[20px] px-0 bg-[#cff0ff] left-0 top-[77px] max-[600px]:top-[90px] text-center list-none overflow-hidden animate-open-menu" : "hidden h-0"}>
        {MobileNavRoutes.map((mobileMenu) => (
          <li key={mobileMenu.id} id={mobileMenu.id}>
            {mobileMenu.item === "Title" ? (
              <span className="text-[#170556] block w-[100%] py-[12px] px-[24px] no-underline font-['Baloo2Bold'] text-[1.30em]">
                {mobileMenu.label}
              </span>
            ) : mobileMenu.item === "TitleLink" ? (
              <a 
                href={mobileMenu.path} 
                target={mobileMenu.target} 
                rel={mobileMenu.rel}
                className="text-[#170556] hover:text-[#fff] block w-[100%] py-[12px] px-[24px] no-underline hover:bg-[#1f4486] shop-nav-link"
              >
                <span className="font-['Baloo2Bold'] text-[1.30em] border-b-[3px] border-solid border-[#170556] shop-nav-text">
                  {mobileMenu.label}
                </span>
              </a>
            ) : (
              (mobileMenu.path.includes("http") ? (
                <a 
                  href={mobileMenu.path} 
                  target={mobileMenu.target} 
                  rel={mobileMenu.rel}
                  className="text-[#170556] hover:text-[#fff] block w-[100%] py-[12px] px-[24px] no-underline hover:bg-[#1f4486] font-sans text-[1em]"
                >
                  {mobileMenu.label}
                </a>
                ) : (
                <Link to={mobileMenu.path} className="text-[#170556] hover:text-[#fff] block w-[100%] py-[12px] px-[24px] no-underline hover:bg-[#1f4486] font-sans text-[1em]">
                  {mobileMenu.label}
                </Link>
                )
              )
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MobileNavbar