import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { aboutUsMenu, whatWeDoMenu, howYouCanHelpMenu, youCanBeAPartOfItMenu } from '../../routes/DesktopNavRoutes';

const MenuDropdowns = ({ submenus, dropdowns }) => {
  return (
    <div className={dropdowns ? "block absolute left-0 top-[50px] z-[9999] w-[100%] bg-[#cff0ff] py-[1.5rem] px-[3.5rem] animate-open-menu" : "hidden"}>
      {submenus.map((submenu) => (
      <div key={submenu.id} id={submenu.id} className="flex justify-between text-[#170556] text-center">
        <div className="w-[22%]">
          <h1 className="text-[#170556] text-left text-[2.5em] mb-[15px]">{submenu.heading}</h1>
          <p className="text-[#170556] text-left font-normal m-0">{submenu.text}</p>
        </div>
        <div>
          <h2 className="text-[1.6em] mb-[10px] text-left">{submenu.subheadingOne}</h2>
          {submenu.subheadingOne === "About Us" ? (
            <ul className="list-none font-normal p-0">
              {aboutUsMenu.map((route) => (
                <li key={route.id} id={route.id} className="mb-[10px]">
                  <Link className="capitalize no-underline font-sans text-[1.25rem] font-[300] inline-block break-words w-[100%] text-[#170556] hover:text-[#630bad] py-[4px]" to={route.path}>
                    {route.label}
                  </Link>
                </li>
              ))}
            </ul>
          ) : submenu.subheadingOne === "How You Can Help" ? (
            <ul className="list-none font-normal p-0">
              {howYouCanHelpMenu.map((route) => (
                <li key={route.id} id={route.id} className="mb-[10px]">
                  <a 
                    className="capitalize no-underline font-sans text-[1.25rem] font-[300] inline-block break-words w-[100%] text-[#170556] hover:text-[#630bad] py-[4px]"
                    href={route.path}
                    target={route.target}
                    rel={route.rel}
                  >
                    {route.label}
                  </a>
              </li>
              ))}
            </ul>
          ) : (
            <ul className="list-none font-normal p-0">
              {youCanBeAPartOfItMenu.map((route) => (
                <li key={route.id} id={route.id} className="mb-[10px]">
                  <Link className="capitalize no-underline font-sans text-[1.25rem] font-[300] inline-block break-words w-[100%] text-[#170556] hover:text-[#630bad] py-[4px]" to={route.path}>
                    {route.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          <h2 className="text-[1.6em] mb-[10px] text-left">{submenu.subheadingTwo}</h2>
          {submenu.subheadingTwo === "What We Do" ? (
            <ul className="list-none font-normal p-0">
              {whatWeDoMenu.map((route) => (
              <li key={route.id} id={route.id} className="mb-[10px]">
                <Link className="capitalize no-underline font-sans text-[1.25rem] font-[300] inline-block break-words w-[100%] text-[#170556] hover:text-[#630bad] py-[4px]" to={route.path}>
                  {route.label}
                </Link>
              </li>
            ))}
            </ul>
          ) : ""}
        </div>
        <div className="w-[30%]">
          <ReactPlayer 
            className="w-[100%]"
            controls={true}
            playing={dropdowns ? true : false}
            volume={null}
            muted={true}
            loop={true}
            url={submenu.video}  
            width="100%"
            height="100%"  
          />    
        </div>
      </div>
      ))}
    </div>
  );
};

export default MenuDropdowns;