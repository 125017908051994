import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { HeartIcon, HopeLogo, LittleHeroesLogo,DecreaseFontSize, ResetFontSize, IncreaseFontSize } from './Assets';
import { MobileNavbar } from './Menus';
import BtnComp from './Buttons/BtnComp';
import Context from '../context/Context';
import '../styles/Header.css';

export default function Header () {
  const { windowDimension, decreaseFontScale, increaseFontScale, resetFontScale } = useContext(Context);
  const [accessibility, setAccessibility] = useState(false);
  const handleAccessClicked = () => setAccessibility(!accessibility);

  return (
    <>
      <div className={`accessibility ${windowDimension.winWidth > 992 ? (accessibility ? "show" : "") : ("")}`}>
        <div className="font-size-controls">
          <button 
            className="access-btn" 
            title="Decrease font size"
            onClick={decreaseFontScale}
          >
            <img src={DecreaseFontSize} alt="Decrease font size" />
          </button>
          <button 
            className="access-btn" 
            title="Increase font size"
            onClick={increaseFontScale}
          >
            <img src={IncreaseFontSize} alt="Increase font size" />
          </button>
          <button 
            className="access-btn" 
            title="Reset font size"
            onClick={resetFontScale}
          >
            <img src={ResetFontSize} alt="Reset font size" />
          </button>
        </div>
      </div>
      <div className="header">
        <div className="header_btn">
          {windowDimension.winWidth > 992 ? (
            <a 
              href="https://www.paypal.com/donate/?hosted_button_id=J7NBS6WZ67U52" target="_blank" rel="noreferrer noopener" className="donate-link"
            >
              <BtnComp
                btnIcon={HeartIcon}
                btnText="Donate" 
                btnType="primary-btn"
                btnLength="short-btn"
              />
            </a>
          ) : (
            <a 
              href="https://www.paypal.com/donate/?hosted_button_id=J7NBS6WZ67U52" target="_blank" rel="noreferrer noopener" className="donate-link"
            >
              <BtnComp
                btnIcon={HeartIcon}
                btnText="" 
                btnType="primary-btn"
                btnLength="mini-btn"
              />
            </a>
          )}
        </div>
        <div className="logos">
          <Link to="/" className="logo-container">
            <img src={HopeLogo} alt="HOPE" className="w-[100%]" />
          </Link>
          <Link to="/little-heroes-big-missions" className="logo-container">
            <img src={LittleHeroesLogo} alt="Little Heroes" className="w-[100%]" />
          </Link>
        </div>
        <div className="header_btn">
        {windowDimension.winWidth > 992 ? (
          <button onClick={handleAccessClicked} className="accessibility-btn">
            <BtnComp
              btnIcon=""
              btnText="Accessibility" 
              btnType="secondary-btn"
              btnLength="short-btn"
            />
          </button>
        ) : (
          <div>
            <MobileNavbar />
          </div>
        )}
        </div>
      </div>
    </>
  );
}